<template>
  <div class='login_corentercomBg' v-if="$store.state.isInterlinkage">
    <div class="ronqi">
      <img class="leftimg" src="../assets/login/left.png" alt="" />
      <div class="right">
        <div class="title">
          芯对讲调度台
        </div>
        <div class="Jump mouse" @click="JumpOld">
          <span>[</span>
          进入V1.0
          <span>]</span>
        </div>
        <div class="box flex_content">
          <div class="math">
            <div class="el_form">
              <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" class="demo-ruleForm">
                <el-form-item prop="username">
                  <div class="el_form_input flex_align">
                    <img class="el_form_input_user" src="../assets/login/user.png" alt="">
                    <el-input type="text" placeholder="输入登录账号" @input="bindInput" maxlength="15"
                      v-model.trim="ruleForm.username">
                    </el-input>
                  </div>
                  <!-- autocomplete="on" -->
                </el-form-item>
                <el-form-item prop="password">
                  <div class="el_form_input flex_align">
                    <img class="el_form_input_pass" src="../assets/login/pass.png" alt="">
                    <el-input type="password" v-model="ruleForm.password" @input="bindInputpassword"
                      autocomplete="new-password" maxlength="16" placeholder="输入密码">
                    </el-input>
                  </div>
                  <!-- autocomplete="on" -->
                </el-form-item>
                <el-form-item>
                  <el-button @click="submitForm('ruleForm')" v-preventReClick class="login-submit" :style="radio == 1
                    ? 'background-color: #4affff;'
                    : 'background-color:#ffba17;'
                    ">登录</el-button>
                </el-form-item>
              </el-form>
            </div>
            <div class="bottom">
              <div style="color: #DCDCDC;">
                记住账号？
                <el-switch v-model="checked" active-color="#4affff" inactive-color="#838383">
                </el-switch>
              </div>
              <a class="el_form_a" :href="xzURL">安装语音服务</a>
            </div>
          </div>
        </div>
        <div class="development">
          <div>深圳市博纳德信息技术有限公司</div>
          <div style="margin-top: 10px">v2.0.1</div>
        </div>
      </div>
      <img class="rightimg" src="../assets/login/right.png" alt="" />
    </div>
  </div>
</template>

<script></script>
<script>
import { Login } from "@/administration/login.js";
import regfunc from "@/method/form/regfunc.js";
import EventBus from "../eventBus/eventBus.js";
import beasconfig from "@/api/beasconfig.js";
// import globalMethod from "@/method/globalMethod.js";
// var ws;
export default {
  data() {
    var username = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("登录账号不能为空"));
      } if (!regfunc.rNum.test(value)) {
        callback(new Error("账号只能输入数字!"));
      } else {
        callback();
      }
    };
    var password = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("密码不能为空"));
      } else {
        callback();
      }
    };
    return {
      checked: false,
      ruleForm: {
        //账号密码
        username: "", //账号
        password: "", //密码
      },
      radio: 1, //选择tab的下标
      rules: {
        username: [{ validator: username, trigger: "blur" }],
        password: [{ validator: password, trigger: "blur" }],
      },
      tabArray: [
        {
          id: 0,
          url: require("@/assets/login/bonardDesk.png"),
          indexUrl: require("@/assets/login/bonardptt.png"),
          color: "#ffba17",
          style: "width: 155px;height: 35px;",
          show: false
        },
        {
          id: 1,
          url: require("@/assets/login/coreintercomDesk.png"),
          indexUrl: require("@/assets/login/coreintercom.png"),
          color: "#4affff",
          style: "width: 138px;height: 35px;",
          show: false
        },
      ],
      xzURL:beasconfig.xinextendUrlapi, //安装包路径
      loginType:0,
      loading:false
    };
  },
  created() {
    //记住密码判断是否记住密码 记住密码则在一开始就渲染上去
    this.xzURL = `${beasconfig.xinextendUrlapi}/dispatcher.exe?${Math.random()}`;
    let userNumber = JSON.parse(localStorage.getItem("userNumber"));
    // console.log(this.$Base64.encode('1111111111,1111111111111'))
    let encryption = this.$route.query.encryption;
    if(encryption){   //判断是否是从超链接进来
      this.$store.state.isInterlinkage = false;
      let encryptionArr = this.$Base64.decode(this.$route.query.encryption).split(","); //encode加密
      this.ruleForm.username = encryptionArr[0];
      this.ruleForm.password = encryptionArr[1];
      this.loginType = 1;
      setTimeout(()=>{
        this.loginView();
      },1000)
    }else{
      this.$store.state.isInterlinkage = true;
      this.loginType = 0;
      if (userNumber && userNumber.checked) {
        this.ruleForm.username = userNumber.userName;
        this.ruleForm.password = userNumber.password;
        this.checked = true;
      }
    }
    console.log('我在登录页面')
    this.$forceUpdate();
  },
  // beforeUpdate() {
  //   console.log('----beforeUpdate----')
  // },
  mounted() {
    
  },
  methods: {
    JumpOld(){
      window.location.href = beasconfig.newAgedUrl;
    },
    bindInput(value) {        //不能输入
      this.$forceUpdate();
      this.ruleForm.username = value.replace(/[^\d]/g, '')
      this.$store.state.userName = this.ruleForm.username;
      // let reg = /^[0-9]{1,15}$/g;
      // let n = value.search(reg);
      // if (n === -1 && value.length > 1) {
      //   this.ruleForm.username = value.slice(0, n);
      // }
    },
    bindInputpassword(value){
      this.ruleForm.password = value.replace(/\s/g,'');//globalMethod.convertChineseSymbolsToEnglish(value,1);
    },
    submitForm(formName) {
      //登录
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let _this = this;
          _this.loginView();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    loginView(){
        let _this = this;
        if(_this.loading == true){
          return
        }
        _this.loading = true;
        const setTime = setTimeout(()=>{
          _this.loading = false;
        },3000)
        if(_this.$store.state.isLinkSuccess){
            let data = {
            userName: _this.ruleForm.username.trim(),
            password: _this.ruleForm.password.trim(),
            version: 2.1,
          };
          Login(data)
            .then((res) => {
              if (res.data.code == 200) {
                if (res.data.data.User_Type != 3) {
                  _this.$MessageWarning('登录失败,该账号不是调度员！');
                  _this.$store.state.isInterlinkage = true;
                } else if (res.data.data.User_Enable == 1) {
                  sessionStorage.setItem(
                    "userInfo",
                    JSON.stringify(res.data.data)
                  );
                  data.type = _this.loginType;
                  sessionStorage.setItem("userNumber", JSON.stringify(data));
                  data.checked = _this.checked;
                  localStorage.setItem("userNumber", JSON.stringify(data));
                  EventBus.$emit('login')
                } else {
                  _this.$store.state.isInterlinkage = true;
                  _this.$MessageWarning('账号不存在！');
                }
              } else {
                _this.$store.state.isInterlinkage = true;
                _this.$MessageWarning(res.data.msg)
              }
            })
            .catch((error) => {
              _this.$store.state.isInterlinkage = true;
              // console.log(error); //请求失败返回的数据
              _this.$MessageWarning('登录异常,请重试!')
            });
          }else{
              _this.loading = false;
              _this.$store.state.isInterlinkage = true;
              clearTimeout(setTime);
              _this.$MessageWarning('请安装语音服务，若安装请刷新浏览器！');
          }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  },
  beforeRouteEnter(to, from, next) {   //监听浏览器回退 回退到登录页面就退出登录
        next(vm => {
            if(from.name != null && from.name != "Login"){
              EventBus.$emit("changePassword");
            }
        })
    },
};
</script>

<style lang="less" scoped>
#login_bnd {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  overflow: hidden;
  background: url(../assets/login/bonardBg.jpg);
  background-size: 100% 100%;
}

.login_corentercomBg {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: url(../assets/login/coreintercomBg.png);
  background-size: 100% 100%;
}

.ronqi {
  display: flex;
  margin: auto;
}

.right {
  margin: 0px 134px;

  .title {
    width: 100%;
    font-size: 60px;
    font-weight: bold;
    color: #4affff;
    text-align: center;
    margin-bottom: 24px;
  }

  .Jump {
    // padding:24px 0px;
    font-size: 14px;
    color: #4affff;
    text-align: center;
    letter-spacing: 2px;

    &>span {
      font-size: 18px;
    }

    &>span:first-child {
      margin-right: 5px;
    }

    &>span:last-child {
      margin-left: 5px;
    }
  }

  .box {
    width: 594px;
    height: 427px;
    background: url(../assets/login/login.png);
    border-radius: 10px;

    .math {
      width: 365px;
      margin: auto;
    }

    .el_form {
      margin-top: 50px;

      &_input {
        border-bottom: 1px solid #4BFFFE;

        &_user {
          width: 25px;
          height: 26px;
        }

        &_pass {
          width: 24px;
          height: 26px;
        }
      }

      .login-submit {
        width: 100%;
        height: 48px;
        color: #343434;
        // background-color: #4affff;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 5px;
        border: 1px solid rgb(74, 255, 255);
        margin-top: 25px;
      }
    }

    .bottom {
      color: #838383;
      font-size: 13px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .development {
    width: 100%;
    text-align: center;
    color: #2c979b;
    font-size: 12px;
    // margin-top: 30px;
    position: fixed;
    bottom: 65px;
    text-align: center;
    left: 0px;
  }

  .el_form_a {
    color: #00FEFF;
    font-size: 13px;
    letter-spacing: 2px;
    font-weight: bold;
  }
}

.leftimg {
  width: 406px;
  height: 277px;
  margin-top: 30px;
}

.rightimg {
  width: 409px;
  height: 258px;
  margin-top: 30px;
}

/deep/ .el-input__inner {
  background-color: transparent;
  border: none;
  padding: 0px 10px;
  color: #ffffff;
  height: 50px;
  line-height: 50px;
}

/deep/ .el-input__inner::placeholder {
  color: #ffffff !important;
}
</style>